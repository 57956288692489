import * as React from 'react';
import Slider from 'react-slick';

const QuotesAboutVersionPress = () => (
  <section className='slice-sm' style={{ backgroundColor: '#f4f4f4' }}>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <Slider autoplay={true} autoplaySpeed={10000}>
            <SaidAboutVersionPress
              imageUrl='https://res.cloudinary.com/vpimg/image/upload/c_scale,h_35/v1538742874/versionpress.com/sitepoint-logo.png'
              targetUrl='https://www.sitepoint.com/versionpress-version-control-comes-to-wordpress/'
            >
              “A ground-breaking plugin for all developers.”
            </SaidAboutVersionPress>

            <SaidAboutVersionPress
              imageUrl='https://wptavern.com/wp-content/themes/stargazer-child-dev/images/tavernlogo.png'
              targetUrl='https://wptavern.com/'
            >
              “This could be the next big thing”
            </SaidAboutVersionPress>

            <SaidAboutVersionPress
              imageUrl='https://res.cloudinary.com/vpimg/image/upload/c_scale,h_20/v1538585050/versionpress.com/kinsta-logo.png'
              targetUrl='https://kinsta.com/blog/versionpress-version-control-wordpress/'
            >
              “Easy to use staging area, and you have a winner”
            </SaidAboutVersionPress>

            <SaidAboutVersionPress
              imageUrl='https://torquehhvm-wpengine.netdna-ssl.com/wp-content/themes/torque/images/TORQUE-logo-full.svg'
              targetUrl='https://torquemag.io/versionpress-git-version-control-comes-wordpress/'
            >
              “Addresses a problem I still haven’t seen anybody solve”
            </SaidAboutVersionPress>

            <SaidAboutVersionPress
              imageUrl='https://res.cloudinary.com/vpimg/image/upload/v1538585840/versionpress.com/hongkiat-logo.png'
              targetUrl='https://www.hongkiat.com/blog/wordpress-version-control/'
            >
              “VersionPress is able to produce smaller backup sizes”
            </SaidAboutVersionPress>

            <SaidAboutVersionPress
              imageUrl='https://res.cloudinary.com/vpimg/image/upload/v1538585916/versionpress.com/fb-group-logo.png'
              targetUrl='https://www.facebook.com/groups/advancedwp/permalink/746696305392586/'
            >
              “OMG OMG OMG OMG”
            </SaidAboutVersionPress>
          </Slider>
        </div>
      </div>
    </div>
  </section>
);

export default QuotesAboutVersionPress;

const SaidAboutVersionPress: React.SFC<{
  imageUrl: string;
  targetUrl: string;
}> = props => (
  <div className='text-center'>
    <p className='lead line-height-1_8'>{props.children}</p>
    <div>
      <a href={props.targetUrl} style={{ display: 'inline-block' }}>
        <img src={props.imageUrl} style={{ display: 'inline', maxHeight: '60px', maxWidth: '150px' }} />
      </a>
    </div>
  </div>
);
