import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layouts/Layout';
import BiggerDescription from '../components/homepage/BiggerDescription';
import Image from '../components/Image';
import Link from '../components/Link';
import QuotesAboutVersionPress from '../components/shared/QuotesAboutVersionPress';
import BottomHero from '../components/shared/BottomHero';
import FancyPageHeader from '../components/shared/FancyPageHeader';

class IndexPage extends React.Component<{}, {}> {
  render() {
    return (
      <Layout className='open-source'>
        <Helmet titleTemplate=''>
          <title>WordPress + Git = VersionPress</title>
        </Helmet>
        <FancyPageHeader title='WordPress + Git = ♡' subtitle='' />

        <section className='slice-lg'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='fluid-paragraph-2 text-center'>
                  <p className='lead line-height-1_8 strong-400 mt-3'>
                    <strong>VersionPress</strong> is a free and open-source plugin that brings the full power of Git to
                    WordPress. Its key strength is that it version-controls not just files but also the database.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <Image
                  src='https://res.cloudinary.com/vpimg/image/upload/w_818,e_sharpen:214/v1538570666/versionpress.com/versionpress-plugin.png'
                  className='img-fluid img-center rounded z-depth-3-top'
                  alt='VersionPress plugin'
                />
              </div>
            </div>
          </div>
        </section>

        <section className='slice-lg'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='fluid-paragraph-2 text-center'>
                  <p className='lead line-height-1_8 strong-400 mt-3'>Key features include:</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='versionpress-features'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 offset-lg-2 mt-3 mt-lg-0'>
                <h3 className='heading heading-4 strong-500'>All changes tracked in Git</h3>
                <p className='mt-3'>
                  VersionPress automatically creates Git commits for both file and database changes, in natural
                  language.
                </p>
              </div>
              <div className='col-lg-4 offset-lg-1 mt-3 mt-lg-0'>
                <h3 className='heading heading-4 strong-500'>"Undo button for WordPress"</h3>
                <p className='mt-3'>
                  Plugins break, people make mistakes; sometimes, you just want that good old Undo button.
                </p>
              </div>
            </div>
            <div className='row mt-lg-5'>
              <div className='col-lg-4 offset-lg-2 mt-3'>
                <h3 className='heading heading-4 strong-500'>Branching and merging</h3>
                <p className='mt-3'>
                  Git took the pain out of file merging; VersionPress does the same for WP database.
                </p>
              </div>
              <div className='col-lg-4 offset-lg-1 mt-3'>
                <h3 className='heading heading-4 strong-500'>Teamwork via GitHub, GitLab, etc.</h3>
                <p className='mt-3'>
                  You can push to GitHub, your colleagues pull and make the site fully functional in seconds. MySQL is a
                  cache.
                </p>
              </div>
            </div>
            <div className='row mt-lg-5'>
              <div className='col-lg-4 offset-lg-2 mt-3'>
                <h3 className='heading heading-4 strong-500'>Ridiculously efficient backups</h3>
                <p className='mt-3'>VersionPress can store hundreds of full site snapshots to just a few megabytes.</p>
              </div>
              <div className='col-lg-4 offset-lg-1 mt-3'>
                <h3 className='heading heading-4 strong-500'>User-friendliness</h3>
                <p className='mt-3'>
                  We care about UX deeply. VersionPress should <em>not</em> be a low-level tool for geeks.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className='slice-lg'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex justify-content-center flex-wrap'>
                <a
                  href='https://github.com/versionpress/versionpress'
                  className='btn btn-styled btn-base-1 btn-circle btn-icon-left'
                >
                  <i className='icon fab fa-github' /> View project on GitHub
                </a>
                <a
                  href='https://docs.versionpress.net'
                  className='btn btn-styled btn-base-1 btn-outline btn-circle ml-5'
                >
                  Docs
                </a>
              </div>
            </div>
          </div>
        </section>

        <QuotesAboutVersionPress />

        <section className='slice'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='fluid-paragraph-2 text-center'>
                  <p className='lead line-height-1_8 strong-400 mt-3'>
                    Please note that VersionPress is a{' '}
                    <Link to='https://docs.versionpress.net/en/getting-started/about-eap/'>developer preview</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='pt-3 sct-color-2 border-bottom border-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 offset-lg-2'>
                <p className='line-height-1_8 strong-400 text-center'>Supported by:</p>
              </div>
            </div>
            <div className='d-flex justify-content-center flex-wrap'>
              <Supporter
                name='Pippins plugins'
                url='https://pippinsplugins.com/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723211/versionpress.com/sponsors/pippins-plugins.jpg'
              />
              <Supporter
                name='Webikon'
                url='https://www.webikon.sk/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/webikon-sk.png'
              />
              <Supporter
                name='ThemeIsle'
                url='https://themeisle.com/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723211/versionpress.com/sponsors/themeisle.png'
              />
              <Supporter
                name='Angry Creative'
                url='https://angrycreative.se/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/angry-creative.png'
              />
              <Supporter
                name='A2 Hosting'
                url='https://www.a2hosting.com/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/a2hosting.png'
              />
              <Supporter
                name='WP Page Builder'
                url='https://livecomposerplugin.com/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/mink.png'
              />
              <Supporter
                name='Kickassd'
                url='https://kickassd.com/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/kickassd.png'
              />
              <Supporter
                name='Warner Media'
                url='https://warner-media.de/'
                iconUrl='https://res.cloudinary.com/vpimg/image/upload/w_30/v1542723210/versionpress.com/sponsors/warner-media.png'
              />
            </div>
          </div>
        </section>

        <section className='slice-lg'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='fluid-paragraph-2 text-center'>
                  <p className='lead line-height-1_8 strong-400 mt-3'>
                    Want to reach out? <a href='mailto:hello@versionpress.com'>hello@versionpress.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <BottomHero>
          <div className='mt-3 mb-5'>
            <Link
              to='https://github.com/versionpress/versionpress'
              className='btn btn-styled btn-base-2 btn-circle btn-icon-left'
            >
              <i className='icon fab fa-github' /> Download from GitHub
            </Link>
            <Link to='https://docs.versionpress.net' className='btn btn-styled btn-base-5 btn-outline btn-circle ml-5'>
              Docs
            </Link>
            <Link
              to='https://gitter.im/versionpress/versionpress'
              className='btn btn-styled btn-base-5 btn-outline btn-circle ml-5'
            >
              Gitter chat
            </Link>
          </div>
        </BottomHero>
      </Layout>
    );
  }
}

export default IndexPage;

const Supporter = (props: { name: string; url: string; iconUrl: string }) => (
  <div className='icon-block icon-block--style-2-v1 text-center d-inline-block' style={{ width: 120 }}>
    <div className='block-icon mb-0'>
      <Image src={props.iconUrl} maxWidth={30} />
    </div>
    <p className='px-2'>
      <Link to={props.url} className='text-md'>
        {props.name}
      </Link>
    </p>
  </div>
);
